<template>
    <div>
<!-- 
       <button @click="isShow='InfoBasicInfoEdit'"
        :class="{active:isShow==='InfoBasicInfoEdit'}"
       >InfoBasicInfoEdit</button> -->
       <!-- <button @click="isShow='InfoBasic'"
       :class="{active:isShow==='InfoBasic'}"
       >InfoBasic</button> -->
       <!-- <button @click="isShow='InfoEdit'" 
       :class="{active:isShow==='InfoEdit'}"
       >InfoEdit</button> -->

       <!-- v-if的方式来切换视图:  创建和销毁，(导致组件的状态不能留存) -->
       <!-- <InfoBasicInfoEdit v-if="isShow==='InfoBasicInfoEdit'"></InfoBasicInfoEdit> -->
       <!-- <InfoBasic v-if="isShow==='InfoBasic'"></InfoBasic>
       <InfoEdit v-if="isShow==='InfoEdit'"></InfoEdit> -->

       <!-- v-show的方式切换组件：  显示和隐藏，没有办法操作DOM结构 -->
       <!-- <InfoBasicInfoEdit v-show="isShow==='InfoBasicInfoEdit'"></InfoBasicInfoEdit> -->
       <InfoBasic ref="basic" v-show="isShow==='InfoBasic'" @handleShow = "handleShow"></InfoBasic>
       <InfoEdit   ref="edit" v-show="isShow==='InfoEdit'" @handleShow="handleShow"></InfoEdit>


       <!-- 动态组件切换： 可以操作DOM, 并且可以留存下数据
        -->
       <!-- <keep-alive>
         <transition name='slide-fade'>
               <component :is="isShow"></component>
         </transition>
   
       </keep-alive> -->
    </div>
</template>


<script>
export default{
    mounted(){

    },
       data() {
             return {
                isShow:'InfoBasic'
             }
       },
       methods:{
        handleShow(a){
            console.log(666,a);
            this.isShow =a
			this.$refs.basic.getInfo()
			this.$refs.edit.getInfo()
        }
       },
       components:{
             InfoBasic:()=>import('./InfoBasic.vue'),
             InfoEdit:()=>import('./InfoEdit.vue')
    }
}
</script>
<style lang="scss" scoped>
    .active{
   background:hotpink;
   color:#ccc;
   }
//    .slide-fade-enter-active {
//    transition: all .3s ease;
//    }
    .slide-fade-leave-active {
   transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
   }
    .slide-fade-enter, .slide-fade-leave-to {
   transform: translateX(10px);
   opacity: 0;
   }  

</style>
